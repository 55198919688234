const blogdata = [
  {
    id: 1,
    title: "Windows 11",
    category: "Technology",
    desc: "Microsoft on yesterday (June 24) launched a new Windows OS named as Windows 11 it is smart upgrade on windows 10 which was launched 6 Years ago....",
    url: "https://blog.aboutrohit.in/windows11",
    img: "https://blog.aboutrohit.in/_next/image?url=https%3A%2F%2Fcdn.hashnode.com%2Fres%2Fhashnode%2Fimage%2Fupload%2Fv1627554872484%2F32S9CBegf.png%3Fw%3D1600%26h%3D840%26fit%3Dcrop%26crop%3Dentropy%26auto%3Dcompress%2Cformat%26format%3Dwebp&w=3840&q=75",
  },
  {
    id: 2,
    title: "Free Domains by FreeNom",
    category: "Technology",
    desc: "Freenom is domain Registrar Company whose Headquarters are in Amsterdam, Netherlands and founded by Joost Zuurbier on 2012..",
    url: "https://blog.aboutrohit.in/freenom",
    img: "https://blog.aboutrohit.in/_next/image?url=https%3A%2F%2Fcdn.hashnode.com%2Fres%2Fhashnode%2Fimage%2Fupload%2Fv1627554926843%2ForCWUHJ_u.png%3Fw%3D1600%26h%3D840%26fit%3Dcrop%26crop%3Dentropy%26auto%3Dcompress%2Cformat%26format%3Dwebp&w=3840&q=75",
  },
  {
    id: 3,
    title: "Domain Name System",
    category: "Technology",
    desc: "Domain Name System is the Human Redable Name of the website that refers to a Particular IP Addresses....",
    url: "#",
    img: "https://blog.aboutrohit.in/_next/image?url=https%3A%2F%2Fcdn.hashnode.com%2Fres%2Fhashnode%2Fimage%2Fupload%2Fv1627554872484%2F32S9CBegf.png%3Fw%3D1600%26h%3D840%26fit%3Dcrop%26crop%3Dentropy%26auto%3Dcompress%2Cformat%26format%3Dwebp&w=3840&q=75",
  },
];

export default blogdata;